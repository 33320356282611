div.fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    border: 5px solid red;

}
.shadow {
    box-shadow: 0 0 0 1px orangered;
}
