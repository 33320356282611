
.fmContainer{
    margin: 0 auto 0;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: gray;
}
.rqFullname{
    color: #00aced;
    font-weight: bold;
}

.rdThead{
    font-size: 16px;
    color: #7b8188;
    font-weight: bold;
}
.rdTd{
    font-size: 14px;
    color: #7b8188;
    text-transform: uppercase;
}
.rdTh{
    font-size: 16px;
    color: #282c34;
    text-transform: capitalize;
    font-weight: bold;
}
.mainTitle{
    color: #7b8188;
    font-weight: bold;
    font-size: 16px;
    font-family: cursive;
    text-align: center;
    margin-bottom: 25px;
}
.myDashBox button{
    margin-left: 25px;
    height: 150px;
    width: 150px;
    font-size: 18px;
    color: white;
    background-color: black;
}

.myDashBox span{
    font-weight: bold;
    font-size: 16px;
    height: 64px;
    width: 68px;


}